<template>
    <v-sheet
        width="100%"
        height="12"
        color="var(--tg-theme-secondary-bg-color)"
    >

    </v-sheet>
</template>

<script>
export default {
  name: "DividerComponent"
}
</script>

<style scoped>
</style>